.viewClientHeadaer {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

.searchBar input {
    padding: 8px;
    outline: 0;
    border: 1px solid #808080a2;
    border-radius: 15px;
    width: 80%;
    padding-left: 28px;
}

.searchBar input::placeholder {
    color: #808080a2;

}

.searchBar svg {
    position: relative;
    top: 8px;
    right: 173px;
    cursor: pointer;
    color: gray;
}

.viewClientRight {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.viewClientRight button svg {
    position: relative;
    top: 2px;
    font-size : 20px;
    right : 2px;
}

img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
}

.viewClientTable svg {
    color: #030037;
}

@media only screen and (max-width : 470px) {

    .viewClientHeadaer {
        padding: 0;
    }

}

@media only screen and (max-width : 380px) {
    .searchBar svg {
        right: 140px;
    }
}