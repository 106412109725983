.sideBar {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebarTop {
    padding: 15px;
}

.sidebarTop h3 {
    font-size: 20px;
    margin: 0;
    padding: 5px;
}

.searchBarTop input {
    padding: 8px;
    outline: 0;
    border: 1px solid #808080a2;
    border-radius: 15px;
    width: 80%;
    padding-left: 28px;
}

.searchBarTop input::placeholder {
    color: #808080a2;

}

.searchBarTop svg {
    position: relative;
    top: 8px;
    right: 210px;
    cursor: pointer;
    color: gray;
}

.sidebarLinks {
    padding: 20px;
    font-size: 15px;
    font-weight: 600;
}

.sidebarLinks h4 {
    margin: 0;
    color: #B8BABC;
    font-size: 16px;
    text-transform: uppercase;
}

.sidebarLink {
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 10px 0;
}

.sidebarLink a {
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    color: #686687;
}

.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.header svg {
    color: #686687;
    cursor: pointer;
}

.headerText {
    font-weight: 700;
    color: #030037;
    padding-top: 10px;
}

.headerText h3 {
    font-size: 24px;
    margin: 5px 0;
    padding-left: 5px;
}

.headerText p {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}

.headerText span {
    color: #686687;
}

.sideBarBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e3dadaa8;
    height: 80px;
}

.sideBarBottom>.useInfo {
    display: flex;
    align-items: center;
}

.useInfo>.userImage {
    display: flex;
}

.useInfo>.userImage>img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.useInfo>.userDetails h4, p {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: #233244;
    padding-left: 10px;
}

.useInfo>.userDetails p {
    font-size: 12px;
    font-weight: 400;
}

.sideBarBottom>.logoutBtn {
    cursor: pointer;
    color: #8E96A0;
    margin-right: 20px;
}