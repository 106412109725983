.formContainer {
    display: grid;
    place-content: center;
    place-items: center;
    height: 85vh;
}

.formContainer form svg {
    color: #B8BABC;
}

.formContainer form {
    margin-top: 20px;
}

.formInput {
    width: 500px;
}

.formFooter span {
    margin: 7px;
}

.formFooter small {
    font-size: 12px !important;
}


.modal {
    display: grid;
    place-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background: #f8f8f8;
    color: #686687;
    border: 1px solid #000;
    box-shadow: 24px;
    padding: 4px;
    padding-bottom: 50px;
}

@media only screen and (max-width: 600px) {
    .formContainer {
        height: 80vh;
    }

    .formInput {
        width: 300px;
    }

    .modal {
        width: 320px;
    }

}