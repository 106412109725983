@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap');

body {
  background: #F8F9FD !important;
  font-family: 'Nunito Sans' !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 20px !important;
  padding-left: 25px !important;
  font-family: 'Nunito Sans' !important;
}